import React from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';

import { ConnectedHelmet } from '../atoms/ConnectedHelmet';
import { ErrorBoundary } from '../atoms/ErrorBoundary/ErrorBoundary';
import { StructuredDataWebPage, StructuredDataWebSite } from '../atoms/StructuredData';
import { environment } from '../config/environment';
import { PageTypes } from '../constants/Pages';
import { IGame } from '../models/Game/Game';
import { CategorySection } from '../models/GamesList';
import { CategoryPageSEO, CoBrandedPageSEO, GeneralPageSEO, PagesData } from '../models/PagesData';
import { Analytics } from '../services/Analytics/Analytics';
import { DeviceType } from '../services/DeviceDetector';
import { GameService } from '../services/GameService';
import { MetaInfoService } from '../services/MetaInfoService';
import { PageService } from '../services/PageService';
import { PwaManifestConfig, PWAService } from '../services/PWAService';
import { UrlService } from '../services/UrlService';
import { setPageType } from '../store/ducks/pages';
import { HomeTemplate } from '../templates/Home/HomeTemplate';
import { HomeTemplateAdFree } from '../templates/Home/HomeTemplateAdFree';

type HomeProps = {
  games: IGame[];
  pages: (GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO)[];
  currentLang: string;
  location: any;
  url: string;
  dispatch: any;
  homepageCategoriesList: CategorySection[];
  deviceType: DeviceType;
  adFree: boolean;
  subscription: boolean;
};

class HomePageBase extends React.PureComponent<HomeProps> {
  private menuItems = PageService.getTopLevelMenuItems(this.props.pages, this.props.currentLang, this.props.games);

  private pwaConfig: PwaManifestConfig = {
    name: 'Arkadium Games',
    shortName: 'Arkadium',
    icon192Url: `${UrlService.toCDNUrl('/icons/pwa/icon-192.png')}`,
    icon512Url: `${UrlService.toCDNUrl('/icons/pwa/icon-512.png')}`,
    startUrl: `${environment.SITE_BASE_URL}`,
  };

  componentDidMount() {
    this.props.dispatch(setPageType(PageTypes.Home));
    Analytics.trackEvent(Analytics.general.arenaPromoBox(this.menuItems, `arena ${PageTypes.Home}`));
  }

  render() {
    const { games, currentLang, url, pages, homepageCategoriesList, deviceType, adFree, subscription } = this.props;
    const pageSEO: GeneralPageSEO | CategoryPageSEO | CoBrandedPageSEO = PageService.getPageSEOByPageName(
      this.props.pages,
      'Home'
    );

    const pageMeta = {
      name: pageSEO.metaHTMLTitle[currentLang],
      url,
      description: pageSEO.metaHTMLDescription[currentLang],
      image: `${UrlService.toCDNUrl('/images/arkadium_logo_medium_500.png')}`,
      imageWidth: 500,
      imageHeight: 173,
      datePublished: '2018-07-17T08:13:19+00:00',
      dateModified: '2019-09-05T12:22:23+00:00',
    };

    const supportedLocales = PageService.getNonEnLocales(pageSEO);
    const localesPath = supportedLocales.map((item) => ({
      lang: item,
      path: `/`,
    }));

    return (
      <>
        <ConnectedHelmet
          title={pageSEO.metaHTMLTitle[currentLang]}
          meta={[
            ...MetaInfoService.getPageMetaInfo(pageSEO, currentLang, environment.SITE_BASE_URL),
            {
              name: 'description',
              content: pageSEO.metaHTMLDescription[currentLang],
            },
            {
              name: 'apple-mobile-web-app-title',
              content: 'Arkadium',
            },
          ]}
          link={[
            ...MetaInfoService.getLinksInfo('/', localesPath),
            { rel: 'canonical', href: `${environment.SITE_BASE_URL}${url}` },
            {
              rel: 'apple-touch-icon',
              href: UrlService.toCDNUrl('/icons/pwa/icon-180.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '180x180',
              href: UrlService.toCDNUrl('/icons/pwa/icon-180.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '167x167',
              href: UrlService.toCDNUrl('/icons/pwa/icon-167.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '152x152',
              href: UrlService.toCDNUrl('/icons/pwa/icon-152.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '120x120',
              href: UrlService.toCDNUrl('/icons/pwa/icon-120.png'),
            },
            {
              rel: 'apple-touch-icon',
              sizes: '76x76',
              href: UrlService.toCDNUrl('/icons/pwa/icon-76.png'),
            },
            PWAService.manifestLinkObject({ config: this.pwaConfig }),
          ]}
        >
          <style>
            {`
                                [data-header] {
                                    display: none;
                                }
                            `}
          </style>
        </ConnectedHelmet>
        <ErrorBoundary>
          <>
            {!adFree && !subscription ? (
              <HomeTemplate
                homepageCategoriesList={homepageCategoriesList}
                games={games}
                currentLang={currentLang}
                pages={pages}
                deviceType={deviceType}
              />
            ) : (
              <HomeTemplateAdFree
                homepageCategoriesList={homepageCategoriesList}
                games={games}
                currentLang={currentLang}
                pages={pages}
                deviceType={deviceType}
              />
            )}
          </>

          <StructuredDataWebPage pageMeta={pageMeta} />
          <StructuredDataWebSite />
        </ErrorBoundary>
      </>
    );
  }
}

export default connect((state, props: RouteComponentProps) => ({
  games: GameService.gameModelToGame(state.games),
  pages: PagesData.getPages(state.pages),
  currentLang: state.currentLang,
  location: state.router.location,
  url: props.location.pathname,
  homepageCategoriesList: state.gameLists.homepageCategoriesList,
  deviceType: state.deviceType,
  adFree: state.preLoadData.adFree,
  subscription: state.preLoadData.subscription,
}))(HomePageBase);
